jQuery.validator.addMethod(
    "ccexpdate",
    function (value, element) {

    // Initialize todays date   i.e start date
    var today = new Date();
    var startDate = new Date(today.getFullYear(),today.getMonth(),1,0,0,0,0);

    // Initialize End/Expiry date i.e. adding 20 years to expire
    var futureLimitDate= new Date(today.getFullYear()+20,today.getMonth(),1,0,0,0,0);
    var expDate = value;

    var expYearCheck='';

    // Check Date format
    var separatorIndex = expDate.indexOf('/');
    if(separatorIndex==-1)return false; // Return false if no / found

    var expDateArr=expDate.split('/'); 
    if(expDateArr.length>2)return false; // Return false if no num/num format found

    // Check Month for validity
    if(eval(expDateArr[0])<1||eval(expDateArr[0])>12)
    {
        //If month is not valid i.e not in range 1-12
        return false;
    }

    expYearCheck = 2000 + parseInt(expDateArr[1], 10); // Convert it to 20YY format to check year

    // Calculate new exp Date
    expDate=new Date(eval(expYearCheck),(eval(expDateArr[0])-1),1,0,0,0,0);

    if(Date.parse(startDate) <= Date.parse(expDate))
    {
        if(Date.parse(expDate) <= Date.parse(futureLimitDate))
        {
            // Date validated
            return true;
        }
        else
        {
            // Date exceeds future date
            return false;
        }
    }
    else
    {
        // Date is earlier than todays date
        return false;
    }
},
"Must be a valid Expiration Date in the format MM/YY."
);


/* Check if a summernote textarea field has content */
jQuery.validator.addMethod('summernoteHasContent', function(value, element){
    if( $(element).summernote('isEmpty') ){
        return false;
    }else{
        return true;
    }
}, 'This field is required.');


(function(){
    function formatDate(date){
        var y = date.getFullYear().toString();
        var m = (date.getMonth()+1).toString();
        var d = date.getDate().toString();
        return y +'-'+ (m[1]?m:'0'+m[0]) +'-'+ (d[1]?d:'0'+d[0]);
    }

    function isToday(selDate){
        var curDate = new Date();
        curDate = formatDate(curDate);
        if( selDate == curDate ){
            return true;
        }
        return false;
    }
    /* Method checks whether the date selected is today, and if it is, checks whether the time selected is before or after the current time */
    jQuery.validator.addMethod('time_selVsCur', function(value, element, param){
        var date = new Date();
        if( isToday(param) ){
            var start_time = element.value.split(':');
            if( start_time[0] < date.getHours() || (start_time[0] == date.getHours() && start_time[1] < date.getMinutes()) ){
                return false;
            }
        }
        return true;
    }, 'The start time cannot be before the current time.');
}());


/* Method checks for a valid URL without requiring scheme (http://, https://, etc) */
jQuery.validator.addMethod('urlbasic', function(value, element) {
    return this.optional(element) || /^((https?|s?ftp):\/\/)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(value);
}, jQuery.validator.messages.url);


/* This method checks a URL for a response object that returns a status of available */
jQuery.validator.addMethod('remote_username', function(value, element, param) {
    if ( this.optional(element) ) {
        return 'dependency-mismatch';
    }

    if ( element.dataset.currentUsername && value === element.dataset.currentUsername ) {
        return true;
    }

    var previous = this.previousValue(element),
    validator, data;

    if (!this.settings.messages[element.name] ) {
        this.settings.messages[element.name] = {};
    }
    previous.originalMessage = this.settings.messages[element.name].remote;
    this.settings.messages[element.name].remote = previous.message;

    param = typeof param === 'string' && { url: param } || param;

    if ( previous.old === value ) {
        return previous.valid;
    }

    previous.old = value;
    validator = this;
    this.startRequest(element);
    data = {};
    data['id'] = value;
    $.ajax($.extend(true, {
        url: param,
        mode: 'abort',
        port: 'validate' + element.name,
        dataType: 'json',
        data: data,
        context: validator.currentForm,
        success: function( response ) {
            var valid = response.status == 'available';
            var errors, message, submitted;

            validator.settings.messages[element.name].remote = previous.originalMessage;
            if ( valid ) {
                submitted = validator.formSubmitted;
                validator.prepareElement(element);
                validator.formSubmitted = submitted;
                validator.successList.push(element);
                delete validator.invalid[element.name];
                validator.showErrors();
            } else {
                errors = {};
                message = validator.defaultMessage( element, 'remote_username' );
                errors[element.name] = previous.message = $.isFunction(message) ? message(value) : message;
                validator.invalid[element.name] = true;
                validator.showErrors(errors);
            }
            previous.valid = valid;
            validator.stopRequest(element, valid);
        }
    }, param));
    return 'pending';
}, 'This username is unavailable.');


jQuery.validator.addMethod('hasSequentialNumbers', function(value, element) {
    if (this.optional(element)) return true;
    const sequentialPattern = '0123456789';
    for (let i = 0; i < value.length - 2; i++) {
        const threeChars = value.substring(i, i+3);
        if (sequentialPattern.includes(threeChars)) {
            return false;
        }
    }
    return true;
}, 'Your password must not contain sequential numbers (e.g., 123).');


jQuery.validator.addMethod('hasRepeatingChars', function(value, element) {
    return this.optional(element) || /(\w)\1{2,}/.test(value) === false;
}, 'Your password must not contain repeating characters (e.g., 111).');